import React from 'react';
import './EmailSent.css';
import { ResourceManager } from "../../index";
import Header from '../../content/header/Header';
import Footer from '../../content/footer/Footer';
import { useNavigate } from 'react-router-dom';

export let previousLocation;

export function setPreviousLocation(pathname) {
    previousLocation = "/" + pathname.substring(3);
}
export default function EmailSent() {
    const navigate = useNavigate();

    function redirect() {
        if (previousLocation === "//information") {
            navigate("/" + ResourceManager.getLanguage() + "/information");
        }
        else {
            navigate(previousLocation);
        }
        
    }

    return (
        <div className="EmailSent">
            <header className="BMC-Header">
                <Header />
            </header>
            <div className="separator" />
            <div className="EmailSent-Body flex centerAlign">
                <div className="messageBody">
                    <header className="greetMessage border-bottom flex centerAlign">
                        <b className="successfullMessage flex centerAlign horizontalCenter">
                            {ResourceManager.getValue("successEmail")}
                        </b>
                    </header>
                    <div className="bodyCenter">
                        {ResourceManager.getValue("feedbackAppreciation")}
                        <br />
                        <button className="return" onClick={redirect}>
                            {ResourceManager.getValue("return")}
                        </button>
                    </div>
                </div>
            </div>
            <footer className="BMC-Footer">
                <Footer />
            </footer>
        </div>
    );
}