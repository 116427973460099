//@ts-ignore
import resources from "./resources.ts";

export default class resourcesFr extends resources {
	public login: string = "Identification";
	public logout: string = "Se déconnecter";
	public imprint: string = "Mentions légales";
	public dataPrivacy: string = "Protection des données";
	public contactUsTitle: string = "Contactez-nous";
	public termsConditionSale: string = "Conditions générales";
	public copyright: string = "2024 Festo Didactic SE. Tous droits réservés.";
	public contactUs: string = "Contactez-nous";
	public howCanHelp: string = "Comment pouvons-nous vous aider ?";
	public name: string = "Nom*:";
	public country: string = "Pays*:";
	public message: string = "Message*:";
	public submit: string = "Soumettre";
	public mandatoryInfos: string = "Les champs avec une astérisque (*) sont obligatoires.";
	public successEmail: string = "Votre email a été envoyé avec succès !";
	public feedbackAppreciation: string = "Nous apprécions vos retours ! Appuyer sur le bouton suivant pour retourner ou vous étiez.";
	public return: string = "Retour";
	public email: string = "Courriel*:";
	public username: string = "Utilisateur";
	public password: string = "Mot de passe";
	public logInTitle: string = "Se connecter";
	public loginSSO: string = "Se Connecter SSO";
	public loginFailed: string = "Échec de la connexion. Veuillez vérifier les informations d'identification."
	public loginSsoFailed: string = "Échec de la connexion avec l'authentification unique (SSO).";
	public welcomeTo: string = "Bienvenue à";
	public weUseCookies: string = "Nous utilisons des cookies";
	public cookiesPolicyVersionUpdated: string = "Nous avons mis à jour notre politique en matière de cookies depuis la dernière fois que vous l'avez acceptée. Vous devez la revoir et l'accepter à nouveau.";
	public cookiesPolicyDescription: string = "BMC SIM utilise des cookies et des pixels de marketing (provenant également de tiers) pour collecter des informations sur la façon dont les visiteurs utilisent le site web et pour personnaliser le contenu et les annonces. Ces cookies nous aident à vous offrir la meilleure expérience en ligne possible, à améliorer constamment notre site web et à vous proposer des offres adaptées à vos intérêts.";
	public cookiesPolicyButtonsDescription: string = "En cliquant sur le bouton \"{acceptAllCookiesButton}\", vous acceptez l'utilisation des cookies. Pour plus d'informations sur l'utilisation des cookies ou pour modifier vos paramètres, veuillez cliquer sur \"{editCookiesSettingsButton}\". Pour utiliser le site avec uniquement les cookies internes techniquement nécessaires, veuillez cliquer sur \"{onlyNecessaryCookiesButton}\".";
	public acceptAllCookies: string = "Accepter tous les cookies";
	public editYourCookiesSettings: string = "Vous pouvez modifier vos paramètres de cookies \"{linkRedirectHere}\"";
	public onlyNecessaryCookies: string = "Uniquement les cookies nécessaires";
	public linkRedirectHere: string = "ici";
	public cookiePolicy: string = "Politique d'utilisation des cookies";
	public cookiePolicyCookieAcceptedCookiesPolicyDescription: string = "Preuve que l'utilisateur a accepté la politique de cookies";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1: string = "BMC SIM utilise les cookies de différentes manières, notamment aux fins suivantes :";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet1: string = "Pour fournir BMC SIM et d'autres applications que vous utilisez.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet2: string = "Pour vous authentifier et vous identifier lorsque vous utilisez nos sites web et applications afin que nous puissions vous fournir les services demandés.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet3: string = "Pour enregistrer vos paramètres ou le moment où vous avez cessé d'utiliser BMC SIM ou d'autres applications.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet4: string = "Pour mesurer votre utilisation de BMC SIM et d'autres applications afin que nous puissions les améliorer et adapter nos sites web et services en ligne à vos intérêts potentiels.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription1Bullet5: string = "Pour comprendre vos intérêts potentiels afin que nous puissions vous fournir un contenu plus pertinent sur BMC SIM.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2: string = "BMC SIM utilise des cookies avec les fonctions suivantes :";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2CookiesForWebsitePerformanceAnalysisDescription: string = "Les cookies qui nous aident à améliorer notre site web fournissent des statistiques agrégées telles que le nombre total de visiteurs du site web et les zones du site web les plus fréquemment consultées par les utilisateurs. Ces types de cookies peuvent être installés par des prestataires de services d'analyse externes travaillant en notre nom.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2CookiesForWebsitePerformanceAnalysisTitle: string = "Cookies pour l'analyse des performances du site web";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2FunctionalCookiesDescription: string = "Il existe deux types de cookies fonctionnels :{lineBreak}- Cookies qui garantissent les fonctionnalités de base du site web.{lineBreak}- Cookies qui améliorent l'expérience de l'utilisateur.{lineBreak}Ce dernier type de cookies permet au portail de se \"souvenir\" des saisis ou paramètres (p. ex., connexion, langue, taille de la police et autres préférences d'affichage) que vous avez réglés pendant une période spécifique de temps. De cette façon, vous n'avez pas besoin de les régler à chaque fois que vous visitez et naviguez sur le site web.";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription2FunctionalCookiesTitle: string = "Cookies fonctionnels";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesDescription3: string = "Les fonctions et propriétés des différents cookies sont décrites ci-dessous :";
	public cookiePolicyForWhichPurposesDoesFestoUseCookiesTitle: string = "{span}4.{/span}{span}Pourquoi Festo utilise-t-il des cookies ?{/span}";
	public cookiePolicyHowCanYouControlCookiesDescription1: string = "Si vous le souhaitez, vous pouvez contrôler et/ou supprimer les cookies. Vous pouvez découvrir comment procéder ici : {link}.";
	public cookiePolicyHowCanYouControlCookiesDescription2: string = "Vous pouvez supprimer les cookies qui ont été enregistrés sur votre ordinateur et la plupart des navigateurs peuvent être configurés pour empêcher le stockage des cookies. Cependant, vous devrez peut-être ensuite configurer certains paramètres manuellement chaque fois que vous visitez un site et accepter que certaines fonctions peuvent être limitées. La désactivation de tous les cookies peut signifier que certaines des fonctionnalités de base de notre site Web ne peuvent plus être utilisées.";
	public cookiePolicyHowCanYouControlCookiesDescription3: string = "Vous pouvez décider quels types de cookies BMC SIM vous souhaitez autoriser en cliquant sur les champs de sélection dans notre contrôleur de cookies ci-dessous :";
	public cookiePolicyHowCanYouControlCookiesTitle: string = "{span}5.{/span}{span}Comment pouvez-vous contrôler les cookies ?{/span}";
	public cookiePolicyWhatIsACookieDescription: string = "Un cookie est un petit paquet de données échangé entre des programmes informatiques et stocké sur votre appareil final (comme votre ordinateur de bureau, votre ordinateur portable ou votre téléphone intelligent) lorsque vous visitez un site web. Les cookies contiennent un identifiant de cookie, qui agit comme un identifiant unique pour chaque cookie spécifique. Cet identifiant est composé d'une séquence de caractères, qui peut être utilisée pour lier des sites web et des serveurs au navigateur web spécifique dans lequel le cookie est stocké. Cela permet aux sites web et aux serveurs visités de distinguer le navigateur de l'utilisateur des autres navigateurs qui contiennent différents cookies. Un navigateur web spécifique peut être reconnu et identifié via l'identifiant de cookie unique.";
	public cookiePolicyWhatIsACookieTitle: string = "{span}1.{/span}{span}Qu'est-ce qu'un cookie ?{/span}";
	public cookiePolicyWhatPurposesDoCookiesServeDescription1: string = "Les utilisations des informations générées par les cookies sont répertoriées ci-dessous.";
	public cookiePolicyWhatPurposesDoCookiesServeDescription2: string = "En plus des analyses statistiques, ces données et informations sont évaluées pour améliorer la protection et la sécurité des données dans notre entreprise, afin de garantir que les données personnelles que nous traitons sont protégées de la manière la plus efficace.";
	public cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet1: string = "Affichage correct du contenu des sites web.";
	public cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet2: string = "Optimiser le contenu de ces sites et la publicité qui y est affichée.";
	public cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet3: string = "Garantir le fonctionnement à long terme des systèmes informatiques et de la technologie des sites web.";
	public cookiePolicyWhatPurposesDoCookiesServeDescriptionBullet4: string = "Fournir aux autorités compétentes les informations nécessaires aux poursuites en cas de cyber-attaque.";
	public cookiePolicyWhatPurposesDoCookiesServeTitle: string = "{span}3.{/span}{span}À quoi servent les cookies ?{/span}";
	public cookiePolicyWhichTypesOfCookiesExistFirstPartyCookiesDescription: string = "Cookies installés sur votre appareil par les sociétés qui exploitent les sites que vous visitez.";
	public cookiePolicyWhichTypesOfCookiesExistFirstPartyCookiesTitle: string = "Cookies propriétaires";
	public cookiePolicyWhichTypesOfCookiesExistFlashCookiesDescription: string = "Ces cookies sont installés par des sites web proposant du contenu multimédia. Le logiciel Adobe Flash est requis pour afficher ce contenu. Cela accélère la vitesse à laquelle vous pouvez télécharger du contenu et enregistrer des informations.";
	public cookiePolicyWhichTypesOfCookiesExistFlashCookiesTitle: string = "Cookies Flash";
	public cookiePolicyWhichTypesOfCookiesExistPersistentCookiesDescription: string = "Ces cookies restent sur votre appareil lorsque vous fermez votre navigateur web. Ils sont réactivés chaque fois que vous visitez le site web qui a généré ce cookie à l'origine.";
	public cookiePolicyWhichTypesOfCookiesExistPersistentCookiesTitle: string = "Cookies persistants";
	public cookiePolicyWhichTypesOfCookiesExistSessionCookiesDescription: string = "Ces cookies ne sont pas stockés en permanence sur votre appareil. Ils sont utilisés pour améliorer votre expérience de navigation sur un site web, par exemple en vous permettant de basculer entre les sites web sans avoir à vous reconnecter. La fermeture de votre navigateur web supprime tous les cookies de session de votre ordinateur.";
	public cookiePolicyWhichTypesOfCookiesExistSessionCookiesTitle: string = "Cookies de session";
	public cookiePolicyWhichTypesOfCookiesExistThirdPartyCookiesDescription: string = "Les entreprises installent ces cookies à des fins d'analyse de site Web, pour fournir à l'opérateur des informations telles que le nombre de visiteurs sur une page Internet particulière.";
	public cookiePolicyWhichTypesOfCookiesExistThirdPartyCookiesTitle: string = "Cookies tiers";
	public cookiePolicyWhichTypesOfCookiesExistTitle: string = "{span}2.{/span}{span}Quels types de cookies existent ?{/span}";
	public youMustAcceptTheCookiePolicyBeforeUsingTheCookieController: string = "You must accept the {link}cookies policy{/link} before using the cookie controller.";
	public category: string = "Catégorie";
	public duration: string = "Durée";
	public validity: string = "Validité";
	public description: string = "Description";
	public essential: string = "Essentiel";
	public years: string = "années";
	public selection: string = "Sélection";
	public functionalCookies: string = "Cookies fonctionnels";
	public functionalCookiesDescription: string = "Ces cookies vous offrent une expérience plus personnalisée sur BMC SIM et se souviennent des choix effectués sur le portail. Par exemple, les cookies de fonctionnalité peuvent être utilisés pour mémoriser les préférences linguistiques.";
	public essentialCookies: string = "Cookies essentiels";
	public essentialCookiesDescription: string = "Cookies essentiels pour les fonctions de base. {lineBreak} Ces cookies sont utilisés pour activer le fonctionnement de base du site Web Festo et de la boutique en ligne. Ces cookies ne peuvent pas être désélectionnés.";
	public cookieName: string = "Nom";
	public information: string = "Information";
	public show: string = "Détails";
	public notRegistered: string = "Non enregistré ?";
	public termsOfUseUpdated: string = "Nous avons mis à jour nos conditions d'utilisation depuis la dernière fois que vous les avez acceptées.";
	public termsAgreement: string = "Accord aux conditions d'utilisation";
	public youNeedToAcceptTerms: string = "Vous devez accepter nos conditions d'utilisation pour accéder à BMC SIM. Veuillez examiner les conditions en cliquant sur le lien suivant : \"{linkTermsOfUse}\"";
	public onceYouHaveReviewedTheTerms: string = "Une fois que vous avez examiné et accepté les conditions, vous pourrez continuer d'utiliser BMC SIM.";
	public byClickingOnAgree: string = "En cliquant sur le bouton \"J'accepte les conditions d'utilisation\", vous acceptez de respecter les règles et les directives énoncées dans notre document des conditions d'utilisation.";
	public termsOfUse: string = "Conditions d'utilisation";
	public agreeToTerms: string = "J'accepte les conditions d'utilisation";
	public forgotPassword: string = "Mot de passe oublié ?";
	public problemsLogIn: string = "Rencontrez-vous des problèmes de connexion à votre compte ?";
	public resetYourPassword: string = "Réinitialiser votre mot de passe";
	public emailAdress: string = "Adresse courriel";
	public resetPassword: string = "Réinitialiser le mot de passe";
	public enterValidEmailAdress: string = "Veuillez entrer une adresse courriel valide.";
	public contactFestoAdmin: string = "Veuillez contacter l'administrateur de Festo pour modifier votre mot de passe.";
	public changePassword: string = "Vous pouvez maintenant changer votre mot de passe. Nous vous avons envoyé un courriel qui vous permet de réinitialiser votre mot de passe.";
	public passwordResetFailed: string = "L'envoi du courriel de réinitialisation du mot de passe a échoué";
	public close: string = "Fermer";
	public checkEmail: string = "Veuillez vérifier vos courriels";
	public continue: string = "Licences";
	public youCannotAccessThis: string = "Une license pour BMC SIM est requise pour accéder au manuel";
	public noBMCLicenseWasFound: string = "Aucune license pour BMC SIM n'a été trouvée";
	public yourOrganizationCurrently: string = "Il n'y a présentement aucune license disponible pour BMC SIM dans votre organisation Festo LX. Vous pouvez accéder à la revue des licenses de votre organisation en cliquant sur \"Licences\". Sur cette page, vous pourrez ajouter une license pour BMC SIM à votre organisation ou vérifier l'utilisation actuelle des licenses existantes.";
	public reducedFunctionality: string = "Vous pouvez continuer votre utilisation de BMC SIM avec les fonctionnalités réduites en cliquant sur \"Mode Démo\".";
	public waitASecond: string = "Attendez une minute...";
	public dismiss: string = "Mode Démo";
}